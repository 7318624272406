import classNames from "classnames";
import React, { CSSProperties, FC, MouseEventHandler } from "react";

import * as HamburgerMenuStyle from "./HamburgerMenuStyle.module.css";

interface Props {
  active: boolean;
  lineHeight: number;
  lineSpacing: number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  width: number;
}

const HamburgerMenu: FC<Props> = ({
  active,
  lineHeight,
  lineSpacing,
  onClick,
  width,
}) => {
  const height = 2 * lineSpacing + 3 * lineHeight;

  return (
    <button
      className={HamburgerMenuStyle.container}
      style={{ height: `${height}px`, width: `${width}px` }}
      type="button"
      onClick={onClick}
    >
      <div
        className={classNames(HamburgerMenuStyle.bar, {
          [HamburgerMenuStyle.active]: active,
        })}
        style={
          {
            "--line-spacing": `${lineSpacing}px`,
            height: `${lineHeight}px`,
          } as CSSProperties
        }
      />
    </button>
  );
};

export default HamburgerMenu;
