import type { GatsbyBrowser } from "gatsby";
import React, { useEffect } from "react";

import "locales/i18n";
import Layout from "./src/components/Main/Layout";
import type { MainContext } from "./src/contexts/MainContext";
import { I18nProvider } from "contexts/I18nContext";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props: { pageContext },
}) => {
  return (
    <I18nProvider>
      <Layout pageContext={pageContext as MainContext}>{element}</Layout>
    </I18nProvider>
  );
};

export const onClientEntry = () => {
  if (process.env.NODE_ENV === "production") {
    const script = document.createElement("script");
    script.id = "cookieyes";
    script.type = "text/javascript";
    script.src =
      "https://cdn-cookieyes.com/client_data/269ee407284d76f67ad41ee4/script.js";
    document.head.appendChild(script);
  }
};
