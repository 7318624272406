// extracted by mini-css-extract-plugin
export var container = "FooterStyle-module--container--4f18a";
export var footer = "FooterStyle-module--footer--b6840";
export var footerBottomRow = "FooterStyle-module--footerBottomRow--4f2bc";
export var footerComponent = "FooterStyle-module--footerComponent--49944";
export var footerMiddleRow = "FooterStyle-module--footerMiddleRow--a8e7c";
export var footerMiddleRowBottomLeft = "FooterStyle-module--footerMiddleRowBottomLeft--ff8af";
export var footerMiddleRowBottomLeftTop = "FooterStyle-module--footerMiddleRowBottomLeftTop--91013";
export var footerMiddleRowBottomLeftTopLogo = "FooterStyle-module--footerMiddleRowBottomLeftTopLogo--b7403";
export var footerMiddleRowBottomRight = "FooterStyle-module--footerMiddleRowBottomRight--c38ce";
export var footerMiddleRowBottonRightSocialContainer = "FooterStyle-module--footerMiddleRowBottonRightSocialContainer--f5250";
export var footerMiddleRowDivider = "FooterStyle-module--footerMiddleRowDivider--7157f";
export var footerPrivacy = "FooterStyle-module--footerPrivacy--bd890";
export var footerSlogan = "FooterStyle-module--footerSlogan--65142";
export var footerSocialIcon = "FooterStyle-module--footerSocialIcon--1f98f";
export var footerSocialIconContainer = "FooterStyle-module--footerSocialIconContainer--2fa56";
export var footerTopRow = "FooterStyle-module--footerTopRow--c4cd2";
export var footerTopRowColumn = "FooterStyle-module--footerTopRowColumn--4eba0";
export var footerTopRowColumnList = "FooterStyle-module--footerTopRowColumnList--f5835";
export var footerTopRowColumnTitle = "FooterStyle-module--footerTopRowColumnTitle--b310d";
export var link = "FooterStyle-module--link--4d272";
export var privacyLink = "FooterStyle-module--privacyLink--134ce";
export var slideAnimation = "FooterStyle-module--slide-animation--efc4c";