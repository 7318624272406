import React from "react";
import useState, { State } from "./NavigationBarState";
import FocusTrap from "focus-trap-react";
import classNames from "classnames";
import * as NavigationBarStyle from "./NavigationBarStyle.module.css";
import * as MainStyles from "../../themes/MainStyles.module.css";
import Link from "../Main/Links";
import { ReactSVG } from "react-svg";
import HamburgerMenu from "./HamburgerMenu";
import PageLinks from "components/Main/PageLinks";
import LanguageSelector from "./LanguageSelector";
import { Theme } from "themes/Theme";

const getTheme = (state: State) => {
  if (
    (location.pathname.includes("about") ||
      location.pathname.includes("services") ||
      location.pathname.includes("privacy")) &&
    state === State.ANCHORED
  ) {
    return Theme.LIGHT;
  }
  return Theme.DARK;
};

const NavigationBar: React.FC = () => {
  const {
    burgerized,
    fullscreenRef,
    hidden,
    homeLinkImageRef,
    homeLinkRef,
    languageSelectorRef,
    navbarRef,
    pageLinkRef,
    state,
    toggleFullscreen,
  } = useState();

  const theme = getTheme(state);

  const logoBlack = {
    publicUrl: "/nordai-title-only-dark.svg",
  };
  const logoWhite = {
    publicUrl: "/nordai-title-only-light.svg",
  };

  const logoUrl = theme === Theme.LIGHT ? logoBlack : logoWhite;

  return (
    <FocusTrap active={state === State.FULLSCREEN}>
      <div
        className={classNames({
          [MainStyles.darkTheme]: theme === Theme.DARK,
          [MainStyles.lightTheme]: theme === Theme.LIGHT,
          [MainStyles.transparentTheme]: theme === Theme.TRANSPARENT,
          burgerized,
        })}
      >
        <div
          ref={navbarRef}
          className={classNames({
            [NavigationBarStyle.anchoredNavBar]:
              state === State.ANCHORED ||
              state === State.FULLSCREEN ||
              state === State.FULLSCREEN_CLOSING,
            [NavigationBarStyle.freeNavBar]: state === State.FREE,
            [NavigationBarStyle.hiddenNavBar]: hidden,
          })}
        >
          <Link
            ref={homeLinkRef}
            className={classNames(
              NavigationBarStyle.link,
              NavigationBarStyle.homeLink
            )}
            to="/"
          >
            <ReactSVG
              afterInjection={homeLinkImageRef}
              className={NavigationBarStyle.homeLinkImage}
              src={logoUrl.publicUrl}
            />
          </Link>

          {burgerized ? (
            <HamburgerMenu
              active={state === State.FULLSCREEN}
              lineHeight={4}
              lineSpacing={6}
              width={30}
              onClick={toggleFullscreen}
            />
          ) : (
            <>
              <PageLinks
                onClick={() => window.scrollTo(0, 0)}
                ref={pageLinkRef}
                className={NavigationBarStyle.link}
              />
              <LanguageSelector
                ref={languageSelectorRef}
                className={NavigationBarStyle.link}
              />
            </>
          )}
        </div>

        {burgerized && (
          <div
            ref={fullscreenRef}
            className={classNames(NavigationBarStyle.fullscreen, {
              [NavigationBarStyle.closed]: state !== State.FULLSCREEN,
            })}
          >
            <PageLinks
              className={NavigationBarStyle.link}
              focusable={state === State.FULLSCREEN}
              onClick={toggleFullscreen}
            />

            <LanguageSelector
              className={NavigationBarStyle.link}
              focusable={state === State.FULLSCREEN}
            />
          </div>
        )}
      </div>
    </FocusTrap>
  );
};

export default NavigationBar;
