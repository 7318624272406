import AOS from "aos";
import React, { FC, ReactNode, useEffect } from "react";

import CanvasProvider from "contexts/CanvasContext";
import ResponsiveProvider from "contexts/ResponsiveContext";
import MainContext from "contexts/MainContext";
import { I18nProvider } from "contexts/I18nContext";

import "aos/dist/aos.css";
import "react-tooltip/dist/react-tooltip.css";
import NavigationBar from "components/Header/NavigationBar";
import Footer from "components/Sections/footer/Footer";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactNode;
  pageContext: import("contexts/MainContext").MainContext;
}

const Layout: FC<Props> = ({ children, pageContext }) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const navigatorLanguage = navigator.language.split("-")[0];
    const language = storedLanguage || navigatorLanguage || "fr";

    i18n.changeLanguage(language);

    AOS.init({ disable: () => false, duration: 600, offset: 80 });
  }, []);

  return (
    <MainContext.Provider value={{ ...pageContext }}>
      <I18nProvider>
        <ResponsiveProvider>
          <CanvasProvider>
            <NavigationBar />
            {children}
            <Footer />
          </CanvasProvider>
        </ResponsiveProvider>
      </I18nProvider>
    </MainContext.Provider>
  );
};

export default Layout;
