import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
} from "react";

export enum Layout {
  DESKTOP = "desktop",
  MOBILE = "mobile",
  TABLET = "tablet",
}

export const LayoutContext = createContext(Layout.DESKTOP);

interface Props {
  children: ReactNode;
}

function isMobile() {
  if (window.matchMedia("(orientation: landscape)").matches) {
    return window.innerWidth <= 823 || window.innerHeight <= 575;
  }

  if (window.matchMedia("(orientation: portrait)").matches) {
    return window.innerWidth <= 767;
  }

  return false;
}

function isTablet() {
  if (window.matchMedia("(orientation: landscape)").matches) {
    return window.innerWidth > 823 && window.innerWidth <= 1024;
  }

  if (window.matchMedia("(orientation: portrait)").matches) {
    return window.innerWidth > 767 && window.innerWidth <= 1024;
  }

  return false;
}

const LayoutState = () => {
  const [layout, setLayoutState] = useState(Layout.DESKTOP);

  useEffect(() => {
    function updateLayout() {
      let effectiveLayout = Layout.DESKTOP;

      if (isMobile()) {
        effectiveLayout = Layout.MOBILE;
      } else if (isTablet()) {
        effectiveLayout = Layout.TABLET;
      }

      if (layout !== effectiveLayout) {
        setLayoutState(effectiveLayout);
      }

      document.documentElement.style.setProperty(
        "--fullscreen-height",
        `${document.documentElement.clientHeight}px`
      );

      document.documentElement.style.setProperty(
        "--fullscreen-width",
        `${document.documentElement.clientWidth}px`
      );
    }

    updateLayout();
    window.addEventListener("resize", updateLayout);

    return () => window.removeEventListener("resize", updateLayout);
  }, [layout]);

  return layout;
};

const ResponsiveProvider: FC<Props> = ({ children }) => {
  const layout = LayoutState();
  return (
    <LayoutContext.Provider value={layout}>{children}</LayoutContext.Provider>
  );
};

export default ResponsiveProvider;
