import { createContext } from "react";

export enum Locale {
  EN = "en",
  FR = "fr",
}

export interface MainContext extends Record<string, unknown> {
  locale: string;
  setLocale: (locale: string) => void;
}

export default createContext<MainContext>({
  locale: Locale.FR,
  setLocale: () => {},
});
