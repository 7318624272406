import classNames from "classnames";
import { Link, navigate } from "gatsby";
import React, { forwardRef, useContext } from "react";
import * as LanguageSelectorStyle from "./LanguageSelectorStyle.module.css";

import MainContext from "contexts/MainContext";
import { I18nContext } from "contexts/I18nContext";

interface Props {
  className?: string;
  focusable?: boolean;
}

const LanguageSelector = forwardRef<HTMLAnchorElement, Props>(
  ({ className, focusable = true }, ref) => {
    const { locale: activeLocale } = useContext(MainContext);
    const { lang, changeLanguage } = useContext(I18nContext);

    const locales = ["en", "fr"];

    const nextLocale = locales.find((locale) => locale !== lang);

    if (!nextLocale) {
      return null;
    }

    return (
      <Link
        ref={ref as typeof ref & string}
        className={classNames(LanguageSelectorStyle.container, className)}
        tabIndex={focusable ? undefined : -1}
        to={location.pathname.replace(activeLocale, nextLocale)}
      >
        <button
          className={LanguageSelectorStyle.languageSelector}
          tabIndex={-1}
          type="button"
          onClick={() => {
            changeLanguage(nextLocale);
          }}
        >
          {nextLocale.toUpperCase()}
        </button>
      </Link>
    );
  }
);

export default LanguageSelector;
