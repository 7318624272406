import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require("./en/index.json"),
    },
    fr: {
      translation: require("./fr/index.json"),
    },
  },
  lng: "fr", // Langue par défaut
  fallbackLng: "fr", // Utilisé si la langue actuelle n'a pas de traduction disponible,
  preload: ["fr", "en"], // Précharge les langues
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
