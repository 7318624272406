import React, { FC } from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import * as FooterStyle from "./FooterStyle.module.css";
import Link from "components/Main/Links";
import { useTranslation } from "react-i18next";
import { RxLinkedinLogo } from "react-icons/rx";

const ContactFooter: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={FooterStyle.footer}>
      <div className={FooterStyle.container}>
        <div className={FooterStyle.footerComponent}>
          <div className={FooterStyle.footerTopRow}>
            <div className={FooterStyle.footerTopRowColumn}>
              <h6 className={FooterStyle.footerTopRowColumnTitle}>
                {t("footer.entrepriseSectionTitle")}
              </h6>

              <ul className={FooterStyle.footerTopRowColumnList}>
                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    className={FooterStyle.link}
                    to="/about"
                  >
                    {t("footer.aboutLink")}
                  </Link>
                </li>

                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    className={FooterStyle.link}
                    to="/services"
                  >
                    {t("footer.servicesLink")}
                  </Link>
                </li>

                <li>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    className={FooterStyle.link}
                    to="/contact"
                  >
                    {t("footer.contactLink")}
                  </Link>
                </li>
              </ul>
              <div className={FooterStyle.footerMiddleRow}>
                <div className={FooterStyle.footerMiddleRowDivider}></div>
                <div className={FooterStyle.footerMiddleRowBottomLeft}>
                  <div className={FooterStyle.footerMiddleRowBottomLeftTop}>
                    <Link
                      className={FooterStyle.footerMiddleRowBottomLeftTopLogo}
                      to="/"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <img src="/nordai-light-title-and-logo.svg" />
                    </Link>

                    <div className={FooterStyle.footerPrivacy}>
                      <Link
                        onClick={() => window.scrollTo(0, 0)}
                        to="/privacy"
                        className={FooterStyle.privacyLink}
                      >
                        {t("footer.privacyLink")}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className={FooterStyle.footerMiddleRowBottomRight}>
                  <div
                    className={
                      FooterStyle.footerMiddleRowBottonRightSocialContainer
                    }
                  >
                    <OutboundLink
                      className={FooterStyle.footerSocialIconContainer}
                      href="https://www.linkedin.com/company/nordai-inc"
                    >
                      <RxLinkedinLogo
                        className={FooterStyle.footerSocialIcon}
                      />
                    </OutboundLink>
                  </div>
                </div>
              </div>

              <div className={FooterStyle.footerBottomRow}>
                <p className={FooterStyle.footerSlogan}>
                  {t("home.title")}
                  <span style={{ color: "#03C144" }}>.</span>
                </p>

                <p className={FooterStyle.footerSlogan}>
                  © {new Date().getFullYear()} NordAI Technologie Inc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFooter;
