import React, { createContext, useState } from "react";
import i18n from "i18next";

interface I18nContextProps {
  lang: string;
  changeLanguage: (lang: string) => void;
}

export const I18nContext = createContext<I18nContextProps>({
  lang: "fr",
  changeLanguage: () => {},
});

export const I18nProvider = ({ children }) => {
  const [lang, setLang] = useState(i18n.language);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
    setLang(lang);
  };

  return (
    <I18nContext.Provider value={{ lang, changeLanguage }}>
      {children}
    </I18nContext.Provider>
  );
};
