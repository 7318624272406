import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactSVG } from "react-svg";

import { Pages, pxValue } from "../../utils/WebsiteUtils";
import { CanvasContext } from "../../contexts/CanvasContext";
import { LayoutContext } from "../../contexts/ResponsiveContext";
import { useTranslation } from "react-i18next";

export enum State {
  ANCHORED,
  FREE,
  FULLSCREEN,
  FULLSCREEN_CLOSING,
}

const FLOATING_POINT_TOLERANCE = 1.5;

function disableScrolling() {
  document.documentElement.style.setProperty("overflow-y", "hidden");
}

function enableScrolling() {
  document.documentElement.style.removeProperty("overflow-y");
}

export default () => {
  const ignoreNext = useRef(false);
  const scrollY = useRef(0);
  const scrollHeight = useRef(0);

  const [state, setState] = useState(State.FREE);
  const [hidden, hide] = useState(true);

  const scroll = useCallback(() => {
    if (document.documentElement.scrollHeight !== scrollHeight.current) {
      ignoreNext.current = true;
    } else if (ignoreNext.current) {
      ignoreNext.current = false;
    } else if (window.scrollY < 1) {
      hide(false);
      setState(State.ANCHORED);
    } else if (
      window.scrollY < scrollY.current - 1 &&
      document.documentElement.scrollHeight >= scrollHeight.current
    ) {
      hide(false);
      setState(State.FREE);
    } else if (window.scrollY >= scrollY.current) {
      hide(true);
    }

    scrollHeight.current = document.documentElement.scrollHeight;
    scrollY.current = window.scrollY;
  }, []);

  useEffect(() => {
    scrollHeight.current = document.documentElement.scrollHeight;
    scrollY.current = window.scrollY;

    scroll();
  }, [scroll]);

  useEffect(() => {
    window.addEventListener("scroll", scroll);
    return () => window.removeEventListener("scroll", scroll);
  }, [scroll]);

  const homeLinkRef = useRef<HTMLAnchorElement>(null);
  const languageSelectorMeasurements = useRef({ width: 0 });
  const languageSelectorRef = useRef<HTMLAnchorElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  const pageLinkMeasurements = useRef({
    font: "",
    letterSpacing: 0,
    marginRight: 0,
  });
  const pageLinkRef = useRef<HTMLAnchorElement>(null);

  const { measureText } = useContext(CanvasContext);
  const layout = useContext(LayoutContext);
  const { t } = useTranslation();

  const [burgerized, setBurgerMode] = useState(false);
  const [homeLinkImageWidth, setHomeLinkImageWidth] = useState(0);

  const homeLinkImageRef = useCallback<
    NonNullable<ReactSVG["props"]["afterInjection"]>
  >(
    (element) => {
      if (element && homeLinkImageWidth === 0) {
        setHomeLinkImageWidth(element.clientWidth);
      }
    },
    [homeLinkImageWidth]
  );

  const pageLinks = useMemo(
    () =>
      Object.values(Pages).map((page) =>
        t(`navbar.${page.replaceAll("/", "")}_link`)
      ),
    [t]
  );

  useEffect(() => {
    function updateLayout() {
      const homeLink = homeLinkRef.current;
      const languageSelector = languageSelectorRef.current;
      const navbar = navbarRef.current;
      const pageLink = pageLinkRef.current;

      if (!homeLink || !navbar || homeLinkImageWidth === 0) {
        return;
      }

      if (languageSelector) {
        languageSelectorMeasurements.current = {
          width: languageSelector.clientWidth,
        };
      }

      if (pageLink) {
        const measurements = getComputedStyle(pageLink);

        pageLinkMeasurements.current = {
          font: measurements.font,
          letterSpacing: pxValue(measurements.letterSpacing),
          marginRight: pxValue(measurements.marginRight),
        };
      }

      const navbarWidth = navbar.offsetWidth;

      const sideMarginsWidth =
        pxValue(getComputedStyle(navbar).paddingLeft) +
        pxValue(getComputedStyle(navbar).paddingRight);

      const contentWidth = navbarWidth - sideMarginsWidth;

      const pageLinksWidth = pageLinks.reduce(
        (accumulator, current) =>
          accumulator +
          measureText({
            font: pageLinkMeasurements.current.font,
            letterSpacing: pageLinkMeasurements.current.letterSpacing,
            text: current,
          }),
        0
      );

      const innerMarginsWidth =
        pxValue(getComputedStyle(homeLink).paddingRight) +
        pageLinkMeasurements.current.marginRight * pageLinks.length;

      const requiredWidth =
        homeLinkImageWidth +
        pageLinksWidth +
        languageSelectorMeasurements.current.width +
        innerMarginsWidth +
        FLOATING_POINT_TOLERANCE;

      setBurgerMode(contentWidth - 200 < requiredWidth);
    }

    updateLayout();
    window.addEventListener("resize", updateLayout);

    return () => window.removeEventListener("resize", updateLayout);
  }, [homeLinkImageWidth, layout, measureText, pageLinks]);

  const [fullscreen, setFullscreen] = useState<HTMLDivElement>();
  const fullscreenRef = useCallback(
    (element: HTMLDivElement) => setFullscreen(element),
    []
  );

  const toggleFullscreen = useCallback(() => {
    if (state !== State.FULLSCREEN) {
      disableScrolling();
      setState(State.FULLSCREEN);
    } else {
      setState(State.FULLSCREEN_CLOSING);
    }
  }, [state]);

  useEffect(() => {
    if (!burgerized) {
      enableScrolling();
      scroll();
    }
  }, [scroll, burgerized]);

  useEffect(() => {
    function reenableScrolling(this: HTMLDivElement) {
      if (getComputedStyle(this).getPropertyValue("height") === "0px") {
        enableScrolling();

        if (window.scrollY < 1) {
          setState(State.ANCHORED);
        }
      }
    }

    fullscreen?.addEventListener("transitionend", reenableScrolling);
    return () =>
      fullscreen?.removeEventListener("transitionend", reenableScrolling);
  }, [fullscreen]);

  return {
    burgerized,
    fullscreenRef,
    hidden,
    homeLinkImageRef,
    homeLinkRef,
    languageSelectorRef,
    navbarRef,
    pageLinkRef,
    state,
    toggleFullscreen,
  };
};
