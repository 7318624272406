import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import { useTranslation } from "react-i18next";
import React, { forwardRef } from "react";

const Link = forwardRef<HTMLAnchorElement, GatsbyLinkProps<unknown>>(
  ({ to, ...props }, ref) => {
    return (
      <GatsbyLink<unknown>
        {...props}
        ref={ref as typeof ref & string}
        to={to}
      />
    );
  }
);

export default Link;
