import React, { Fragment, ReactNode } from "react";

export enum Pages {
  HOME = "/",
  ABOUT = "/about",
  SERVICES = "/services",
  CONTACT = "/contact",
}

export const percentToFloat = (percent: string) => {
  return parseFloat(percent.slice(0, -1)) / 100;
};

export const pxValue = (px: string) => {
  return parseFloat(px.slice(0, -2));
};

export const remValue = (rem: string) => {
  return parseFloat(rem.slice(0, -3));
};

export const remToPx = (remToPx: string) => {
  return pxValue(remToPx) * remValue("1rem");
};
