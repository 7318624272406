import React, {
  createContext,
  FC,
  ReactNode,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export const CanvasContext = createContext({
  measureText: (() => 0) as ({
    font,
    letterSpacing,
    text,
  }: {
    font: string;
    letterSpacing?: number;
    text: string;
  }) => number,
});

interface Props {
  children: ReactNode;
}

const CanvasState: any = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D>();

  useEffect(() => {
    const context = canvasRef.current?.getContext("2d");

    if (context) {
      setContext(context);
    }
  }, []);

  const measureText = useCallback(
    ({
      font,
      letterSpacing = 0,
      text,
    }: {
      font: string;
      letterSpacing?: number;
      text: string;
    }) => {
      if (!context) {
        return 0;
      }

      context.font = font;

      return Math.round(
        context.measureText(text).width + text.length * letterSpacing
      );
    },
    [context]
  );

  return { canvasRef, measureText };
};

const CanvasProvider: FC<Props> = ({ children }) => {
  const { canvasRef, measureText } = CanvasState();

  return (
    <CanvasContext.Provider
      value={useMemo(() => ({ measureText }), [measureText])}
    >
      {children}
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </CanvasContext.Provider>
  );
};

export default CanvasProvider;
