import classNames from "classnames";
import React, { forwardRef, useContext } from "react";

import * as PageLinksStyle from "./PageLinksStyle.module.css";
import MainContext from "contexts/MainContext";
import { Pages } from "utils/WebsiteUtils";
import Link from "./Links";
import { useTranslation } from "react-i18next";

interface StylizeLinkArgs {
  className?: string;
  isActivePage?: boolean;
}

function stylizeLink({ className, isActivePage = false }: StylizeLinkArgs) {
  return classNames(
    PageLinksStyle.pageLink,
    {
      [PageLinksStyle.currentPage]: isActivePage,
    },
    className
  );
}

interface Props {
  className?: string;
  focusable?: boolean;
  onClick?: () => void;
}

const PageLinks = forwardRef<HTMLAnchorElement, Props>(
  ({ className, focusable = true, onClick }, ref) => {
    const { t } = useTranslation();
    const pages = Object.values(Pages).filter((page) => page !== Pages.HOME);

    return (
      <>
        {Object.values(pages).map((page, index) => (
          <Link
            key={page}
            ref={index === 0 ? ref : undefined}
            className={stylizeLink({
              className,
            })}
            onClick={onClick}
            tabIndex={focusable ? undefined : -1}
            to={page}
          >
            {t(`navbar.${page.replaceAll("/", "")}_link`)}
          </Link>
        ))}
      </>
    );
  }
);

export default PageLinks;
